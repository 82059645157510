<template>
  <div class="modal-container" v-if="open">
    <div class="modal">
      <header class="modal-header">
        <button class="btn close" @click="close">Close</button>
      </header>
      <div class="modal-body">
        <textarea class="textarea" @keyup="onKeyUp" v-model="text" placeholder="What consequences might follow or how could the story go on..."></textarea>
        <button class="btn add-doodle" @click="toggleDoodle">{{ hasDoodle ? 'Remove Doodle' : 'Add Doodle' }}</button>
        <Whiteboard v-show="hasDoodle" :image="node.image" />
      </div>
      <footer class="modal-footer">
        <button class="btn save" @click="save">Save</button>
      </footer>
    </div>
  </div>
</template>

<script>
import EventBus from '../EventBus.js'
import Whiteboard from './Whiteboard.vue'

export default {
  name: 'EditModal',
  components: {
    Whiteboard
  },
  data: () => {
    return {
      open: false,
      node: null,
      hasDoodle: false,
      text: ''
    }
  },
  methods: {
    save () {
      if (this.hasDoodle) {
        EventBus.$once('saveWhiteboardSuccess', (image) => {
          this.$socket.emit('editNode', {
            id: this.node.id,
            text: this.text,
            image: image
          })
          this.close()
        })
        EventBus.$emit('saveWhiteboard')
      } else {
        this.$socket.emit('editNode', {
          id: this.node.id,
          text: this.text,
          image: null
        })
        this.close()
      }
    },
    onKeyUp () {
      this.text = this.text.substring(0, 160)
    },
    close () {
      this.open = false
      this.text = ''
      this.hasDoodle = false
    },
    toggleDoodle () {
      this.hasDoodle = !this.hasDoodle
    },
    editNode ({ node }) {
      this.node = node
      this.text = node.text
      if (node.image) {
        this.hasDoodle = true
      }
      this.open = true
    }
  },
  mounted () {
    EventBus.$on('editNode', this.editNode)
  },
  beforeDestroy () {
    EventBus.$off('editNode', this.editNode)
  }
}
</script>

<style lang="scss" scoped>
  .modal-container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000000aa;
    padding: 1rem;
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }

  .modal {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    background: black;
    border: 1px solid white;

    @media screen and (max-width: 768px) {
      height: 100%;
    }
  }

  .modal-header {
    display: flex;
    justify-content: flex-end;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .modal-footer {
    display: flex;
    margin-top: auto;
  }

  .close {
    margin: 10px;
  }

  .textarea {
    min-height: 80px;
  }

  .add-doodle {
    align-self: flex-start;
    margin: 10px;
  }

  .save {
    width: 100%;
    margin: 10px;
  }
</style>
