<template>
  <div class="whiteboard-container">
    <canvas
        class="whiteboard"
        :width="width"
        :height="height"
        ref="canvas"
        @mousemove="onMove"
        @touchmove="onMove"
        @mousedown="onDown"
        @touchstart="onDown"
    />
    <div class="whiteboard-tools">
      <button class="btn" @click="toggleColor">Toggle Color</button>
      <input type="range" min="1" max="30" v-model="radius" />
      <button class="btn" @click="clear">Clear</button>
    </div>
  </div>
</template>

<script>
import EventBus from '../EventBus.js'

let ctx = null

export default {
  name: 'Whiteboard',
  props: {
    image: String
  },
  data () {
    return {
      width: 500,
      height: 500,
      isDown: false,
      radius: 3,
      color: 'white',
      lastX: 0,
      lastY: 0
    }
  },
  methods: {
    getPos (e) {
      const client = { x: 0, y: 0 }
      const rect = ctx.canvas.getBoundingClientRect()

      if (e.clientX) {
        client.x = e.clientX
        client.y = e.clientY
      } else if (e.touches.length === 1) {
        client.x = e.touches[0].clientX
        client.y = e.touches[0].clientY
      } else {
        return false
      }

      return {
        x: (client.x - rect.x) / ctx.canvas.clientWidth * ctx.canvas.width,
        y: (client.y - rect.y) / ctx.canvas.clientHeight * ctx.canvas.height
      }
    },
    onMove (e) {
      if (this.isDown) {
        e.preventDefault()
        const pos = this.getPos(e)
        if (!pos) {
          return false
        }
        const { x, y } = pos

        ctx.strokeStyle = this.color
        ctx.lineWidth = this.radius
        ctx.lineCap = 'round'
        ctx.beginPath()
        ctx.moveTo(this.lastX, this.lastY)
        ctx.lineTo(x, y)
        ctx.stroke()
        this.lastX = x
        this.lastY = y
      }
    },
    onDown (e) {
      const pos = this.getPos(e)
      if (!pos) {
        return false
      }
      const { x, y } = pos

      this.isDown = true
      this.lastX = x
      this.lastY = y

      ctx.fillStyle = this.color
      ctx.beginPath()
      ctx.arc(this.lastX, this.lastY, this.radius * 0.5, 0, 2 * Math.PI)
      ctx.fill()
    },
    onUp (e) {
      this.isDown = false
    },
    clear () {
      const { width, height } = this
      ctx.fillStyle = 'black'
      ctx.fillRect(0, 0, width, height)
    },
    toggleColor () {
      this.color = this.color === 'white' ? 'black' : 'white'
    },
    onSave () {
      const image = this.$refs.canvas.toDataURL()
      EventBus.$emit('saveWhiteboardSuccess', image)
    }
  },
  mounted () {
    window.addEventListener('mouseup', this.onUp)

    ctx = this.$refs.canvas.getContext('2d')
    this.clear()

    if (this.image) {
      const { width, height } = this
      const img = new Image()
      img.crossOrigin = 'anonymous'
      img.onload = () => {
        ctx.drawImage(img, 0, 0, width, height)
      }
      img.src = this.$socket.io.uri + this.image
    }

    EventBus.$on('saveWhiteboard', this.onSave)
  },
  beforeDestroy () {
    EventBus.$off('saveWhiteboard', this.onSave)
    window.removeEventListener('mouseup', this.onUp)
  }
}
</script>

<style scoped lang="scss">
  .whiteboard-container {
    display: flex;
    flex-direction: column;
    align-self: center;
    margin: 10px;
  }

  .whiteboard {
    aspect-ratio: 1/1;
    border: 1px solid white;
    width: 100%;
    max-width: 500px;
    max-height: calc(100vh - 200px);
    margin-bottom: 10px;
  }

  .whiteboard-tools {
    display: flex;
    justify-content: space-between;
  }
</style>
